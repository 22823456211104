@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

::-webkit-scrollbar-track {
    @apply bg-gray-200;
}

::-webkit-scrollbar-thumb {
    @apply bg-gray-400 rounded-lg;
}

::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-600;
}

@layer base {
    @font-face {
        font-family: 'Philosopher-Bold';
        src: url(/assets/fonts/Philosopher-Bold.ttf);
    }

    @font-face {
        font-family: 'Philosopher-BoldItalic';
        src: url(/assets/fonts/Philosopher-BoldItalic.ttf);
    }

    @font-face {
        font-family: 'Philosopher-Italic';
        src: url(/assets/fonts/Philosopher-Italic.ttf);
    }

    @font-face {
        font-family: 'Philosopher-Regular';
        src: url(/assets/fonts/Philosopher-Regular.ttf);
    }

    @font-face {
        font-family: 'Ubuntu-Bold';
        src: url(/assets/fonts/Ubuntu-Bold.ttf);
    }

    @font-face {
        font-family: 'Ubuntu-BoldItalic';
        src: url(/assets/fonts/Ubuntu-BoldItalic.ttf);
    }

    @font-face {
        font-family: 'Ubuntu-Italic';
        src: url(/assets/fonts/Ubuntu-Italic.ttf);
    }

    @font-face {
        font-family: 'Ubuntu-Light';
        src: url(/assets/fonts/Ubuntu-Light.ttf);
    }

    @font-face {
        font-family: 'Ubuntu-LightItalic';
        src: url(/assets/fonts/Ubuntu-LightItalic.ttf);
    }

    @font-face {
        font-family: 'Ubuntu-Medium';
        src: url(/assets/fonts/Ubuntu-Medium.ttf);
    }

    @font-face {
        font-family: 'Ubuntu-MediumItalicc';
        src: url(/assets/fonts/Ubuntu-MediumItalic.ttf);
    }

    @font-face {
        font-family: 'Ubuntu-Regular';
        src: url(/assets/fonts/Ubuntu-Regular.ttf);
    }
}